import { Controller } from "@hotwired/stimulus"
import {
  draggable,
  dropTargetForElements,
  monitorForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import $ from 'jquery'

export default class extends Controller {
  static targets = ["column", "billerSelect", "content", "request"]

  connect() {
    this.initializeDragAndDrop()
    this.setupMutationObserver()
    this.updateColumnVisibility()

    $(this.billerSelectTarget).on('select2:select', (e) => {
      this.handleSelect2Change(e)
    })

    $(this.billerSelectTarget).on('select2:unselect', (e) => {
      this.handleSelect2Change(e)
    })
  }

  disconnect() {
    // Clean up mutation observer
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  setupMutationObserver() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // Check if new nodes were added
        if (mutation.addedNodes.length > 0) {
          // Look for new request targets within the added content
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              const newRequests = node.querySelectorAll('[data-assignment-board-target="request"]')
              if (newRequests.length > 0) {
                this.initializeDraggableRequests(newRequests)
              }
            }
          })
        }
      })
    })

    // Start observing the entire controller element for changes
    this.observer.observe(this.element, {
      childList: true,
      subtree: true
    })
  }

  initializeDragAndDrop() {
    this.setupColumns()
    this.setupRequests()
    this.setupMonitor()
  }

  setupColumns() {
    this.columnTargets.forEach(column => {
      combine(
        dropTargetForElements({
          element: column,
          getData: () => ({
            type: 'column',
            id: column.dataset.columnId
          }),
          canDrop: ({ source }) => source.data.type === 'request'
        })
      )
    })
  }

  initializeDraggableRequests(requests) {
    requests.forEach(request => {
      combine(
        draggable({
          element: request,
          getInitialData: () => ({
            type: 'request',
            id: request.dataset.requestId,
            accountId: request.dataset.accountId,
            currentAssignee: request.closest('[data-column-id]').dataset.columnId
          }),
          onDragStart: () => {
            this.columnTargets.forEach(column => {
              column.classList.remove('bg-gray-100')
              column.classList.add('bg-orange-100')
            })
          },
          onDrop: () => this.resetColumnStyles(),
          onDragEnd: () => this.resetColumnStyles()
        })
      )
    })
  }

  setupRequests() {
    // Initialize existing requests
    this.initializeDraggableRequests(this.requestTargets)
  }

  resetColumnStyles() {
    this.columnTargets.forEach(column => {
      column.classList.remove('bg-orange-100')
      column.classList.add('bg-gray-100')
    })
  }

  showLoading(columnId) {
    const column = this.columnTargets.find(col => col.dataset.columnId === columnId)
    if (column) {
      const loading = column.querySelector('[data-assignment-board-target="loading"]')
      const content = column.querySelector('[data-assignment-board-target="content"]')
      if (loading && content) {
        loading.classList.remove('hidden')
        content.classList.add('hidden')
      }
    }
  }

  hideLoading(columnId) {
    const column = this.columnTargets.find(col => col.dataset.columnId === columnId)
    if (column) {
      const loading = column.querySelector('[data-assignment-board-target="loading"]')
      const content = column.querySelector('[data-assignment-board-target="content"]')
      if (loading && content) {
        loading.classList.add('hidden')
        content.classList.remove('hidden')
      }
    }
  }

  setupMonitor() {
    monitorForElements({
      canMonitor: ({ source }) => source.data.type === 'request',
      onDrop: async ({ source, location }) => {
        const draggingRequest = source.data
        const dropTarget = location.current.dropTargets[0]

        if (!dropTarget) return

        const newAssigneeId = dropTarget.data.id
        const currentAssigneeId = draggingRequest.currentAssignee

        if (newAssigneeId === currentAssigneeId) return

        // Show loading for both affected columns
        this.showLoading(currentAssigneeId)
        this.showLoading(newAssigneeId)

        const csrfToken = document.querySelector("[name='csrf-token']").content
        const action = newAssigneeId === 'unassigned' ? 'unassign' : 'assign'
        const url = `/assignments/${action}`

        try {
          const response = await fetch(url, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken,
              'Accept': 'application/json',
              'Account-Id': draggingRequest.accountId
            },
            body: JSON.stringify({
              request_id: draggingRequest.id,
              assignee_id: newAssigneeId === 'unassigned' ? null : newAssigneeId
            })
          })

          if (response.ok) {
            const oldColumnFrame = `biller_${currentAssigneeId}_requests`
            const newColumnFrame = newAssigneeId === 'unassigned'
              ? 'unassigned_requests'
              : `biller_${newAssigneeId}_requests`

            // Get existing search parameters from the current URL
            const searchParams = window.location.search

            // Build URLs with the current search parameters
            const buildAssignedUrl = (billerId) =>
              `/assignments/assigned_verification_requests${searchParams}${searchParams ? '&' : '?'}biller_id=${billerId}`

            const buildUnassignedUrl = () =>
              `/assignments${searchParams}`

            // Update both frames with their correct URLs
            Promise.all([
              // For the old column
              Turbo.visit(
                currentAssigneeId === 'unassigned'
                  ? buildUnassignedUrl()
                  : buildAssignedUrl(currentAssigneeId),
                {
                  frame: oldColumnFrame,
                  action: 'replace'
                }
              ),
              // For the new column
              Turbo.visit(
                newAssigneeId === 'unassigned'
                  ? buildUnassignedUrl()
                  : buildAssignedUrl(newAssigneeId),
                {
                  frame: newColumnFrame,
                  action: 'replace'
                }
              )
            ])
          } else {
            console.error('Failed to update assignment')
            this.hideLoading(currentAssigneeId)
            this.hideLoading(newAssigneeId)
          }
        } catch (error) {
          console.error('Error updating assignment:', error)
          this.hideLoading(currentAssigneeId)
          this.hideLoading(newAssigneeId)
        }
      }
    })
  }

  // Column visibility
  handleSelect2Change(event) {
    const selectedBillerIds = event.type === 'select2:unselect'
      ? $(this.billerSelectTarget).val().filter(id => id !== event.params.data.id)
      : $(this.billerSelectTarget).val()

    this.updateColumnVisibility(selectedBillerIds)
  }

  updateColumnVisibility() {
    const selectedBillerIds = $(this.billerSelectTarget).val() || []

    this.columnTargets.forEach(column => {
      const columnId = column.dataset.columnId

      if (columnId === 'unassigned') {
        column.classList.remove('hidden')
      } else {
        if (selectedBillerIds.includes(columnId)) {
          column.classList.remove('hidden')
        } else {
          column.classList.add('hidden')
        }
      }
    })
  }
}